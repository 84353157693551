<template>
  <div class="w-full">
    <a-table :columns="columns" :source="supervising" @toolbar(refresh)="fetch" :loading="loading">
      <template #toolbar(search)>
        <h4 class="text-2xl p-2">Profesores supervisados</h4>
      </template>
      <template #toolbar(refresh)>
        <a-button
          :disabled="loading"
          @click="fetch">
          <a-icon name="refresh" :class="['transition-transform', {
                'animate-spin': loading
              }]" />
        </a-button>
        <a-button :disabled="loading" v-if="user.isAdmin" @click="teachersModal = true">
          Asignar Profesor
        </a-button>
      </template>
    </a-table>
    <a-modal
      :title="'Asignar profesor'"
      :show="teachersModal"
      :disabled-confirm="!selectedTeacher || loadingAssign"
      @cancel="teachersModal = false"
      @confirm="assignTeacher">
      <a-select
        placeholder="Seleccione un profesor"
        :source="mappedTeachers"
        v-model="selectedTeacher"
        class="mt-2 text-left"/>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    period: null,
    supervising: [],
    loading: false,
    columns: [
      { title: 'Nombres', key: 'teacher', mutate: ({ item }) => `${item.first_name} ${item.last_name}` },
      { title: 'Correo electrónico', key: 'email' },
      { title: 'teléfono', key: 'phone' },
      { title: 'estado', key: 'enabled', mutate: ({ key }) => key ? 'Habilitado' : 'Deshabilitado' },
    ],
    teachersModal: false,
    teacherList: [],
    selectedTeacher: null,
    loadingAssign: false,
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      teachers: (state) => state.teachers.all,
    }),
    mappedTeachers() {
      // return this.teachers.flatMap($0 => !$0.is_coordinator ? !this.supervising.some($1 => $1.id === $0.id) ? { label: $0.full_name, value: $0.id } : [] : [])
      return this.teachers.map($0 => ({ label: $0.full_name, value: $0.id }))
    }
  },
  methods: {
    ...mapActions({
      fetchTeachers: 'teachers/index'
    }),
    fetch () {
      this.$repository.teachers
        .show(this.$route.params.id, { limit: 0, with: 'supervising' })
        .then(({ data }) => {
          this.supervising = data.supervising
        })
        ._loading(() => this.loading = !this.loading)
    },
    assignTeacher () {
      this.loadingAssign = true
      this.$repository.teachers
        .assign(this.$route.params.id, {target_teachers_ids: [this.selectedTeacher]})
        .finally(() => {
          this.loadingAssign = false
          this.teachersModal = false
          this.selectedTeacher = null
          this.fetch()
        })
    }
  },
  mounted () {
    if (this.user.isAdmin) {
      this.fetchTeachers({ limit: 0, enabled: true, order_by: 'id' })
    }
    this.fetch()
  }
}
</script>
